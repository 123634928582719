import "./sitemap.scss"

import { Link, graphql } from 'gatsby'
import * as React from "react"
import { Container } from "react-bootstrap"
import FindDreamJob from "../components/careers/finddreamjob"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"
const IndexPage = () => {
  const pageTitle = "Sitemap"
  const pageDescription = "BTS sitemap"
  const lang = 'EN'
  const meta = []
  const breadcrumbMap = [
    { title: "Sitemap", url: "#" }
  ]
  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
        <div className="max-container-pages">
          <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
          <Container fluid="xl">
            <section className="section-sitemap">
              <h1>Sitemap</h1>
              <ul>
                <li>
                  <Link to="/">Index</Link>
                </li>
                <li>
                  <Link to="/about/">About BTS</Link>
                </li>
                <li>
                  <Link to="/about/#ourculture">Our Culture</Link>
                </li>
                <li>
                  <Link to="/careers/">Careers</Link>
                </li>
                <li>
                  <Link to="/careers/jobs/">Job Opportunities</Link>
                </li>
                <li>
                  <Link to="/careers/">Join BTS</Link>
                </li>
                <li>
                  <Link to="/refer-a-friend/">Refer a Friend</Link>
                </li>
                <li>
                  <Link to="/services/">Development Services</Link>
                </li>
                <li>
                  <Link to="/products/">Products</Link>
                </li>
                <li>
                  <Link to="/qaservices/">QA Services</Link>
                </li>
                <li>
                  <Link to="/education/">Technology Centers</Link>
                </li>
                <li>
                  <Link to="/internship/">Internship Program</Link>
                </li>
                <li>
                  <Link to="/mentoring/">Mentoring Program</Link>
                </li>
                <li>
                  <Link to="/careers/locations/">Locations</Link>
                </li>
                <ul className="locations-list">
                  <li>
                    <Link to="/careers/location-usa/">USA</Link>
                  </li>
                  <li>
                    <Link to="/careers/location-mexico/">Mexico</Link>
                  </li>
                  <li>
                    <Link to="/careers/location-argentina/">Argentina</Link>
                  </li>
                  <li>
                    <Link to="/careers/location-spain/">Spain</Link>
                  </li>
                  <li>
                    <Link to="/careers/location-uruguay/">Uruguay</Link>
                  </li>
                  <li>
                    <Link to="/careers/location-bolivia/">Bolivia</Link>
                  </li>
                  <li>
                    <Link to="/careers/location-peru/">Peru</Link>
                  </li>
                  <li>
                    <Link to="/careers/location-tunisia/">Tunisia</Link>
                  </li>
                </ul>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul>
            </section>
          </Container>
          <FindDreamJob></FindDreamJob>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
